:root {
    --main-color: #f47121;
}

body{
    background-color: #e9e9e9 !important;
}
h4{
    background-color: var(--main-color);
    color: white;
}

/*Login Form Box Colour*/
.container-LoginForm{
    color: black;
    box-shadow: 2px 2px 8px;
    background-color: white;
}
.form-LoginForm,
.form-pengiriman{
    background-color: white;
}
.logo-container{
    background-color: white;
    border-bottom: 2px solid var(--main-color);
} 
.logo-container-pengiriman{
    background-color: #e9e9e9;
}

/*Input Form Colour*/
.btn-input{
    color: #292929;
}
.btn:not(.btn-secondary){
    background: var(--main-color) !important;
    color: white;
    border: none;
}
.btn-primary:hover {
    border: solid 1px black;
}

/*Side Bar Colour*/
.sidenav{
    background-color: white;
}
.sidenav a{
    color: black;
}
.active-link{
    background-color: var(--main-color);
    color: white !important;
}
.sidenav a:hover{
    color: var(--main-color);
}

/* Table Scrollbar Colour */
::-webkit-scrollbar-track {
    background: #e9e9e9;
}
::-webkit-scrollbar-thumb {
    background: var(--main-color); 
}

/*Table Colour*/
table.dataTable thead th, table.dataTable thead td{
    background: var(--main-color);
    color: white;
}
table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: white;
}
table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
    background-color:  white;
}
table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
    background-color: #e9e9e9;
}
table.dataTable tbody tr {
    background-color: #e9e9e9;
}
.dataTables_length{
    background-color: var(--main-color);
    color: white !important;
    box-shadow: 1px 2px 8px black;
}
.dataTables_wrapper .dataTables_scroll{
    box-shadow: 1px 2px 8px black;
    background: var(--main-color);
}
.table_footer{
    background-color:white;
    box-shadow: 1px 3px 8px black;
}
.dataTables_filter{
    background-color: var(--main-color);
    color: white !important;
}

/*Auto Complete Colour*/
.suggestions{
    border: 1px solid #999;
    background-color: white;
    box-shadow: 1px 2px 5px rgb(43, 41, 41);
}

.suggestion-active,
.suggestions li:hover{
    background-color: #1e90ff;
    color: white;
}

/* Error Colour */
.invalid{
    border: 1px solid red !important;
}
.unauthorize-container h1:hover{
    color: var(--main-color);
}
#logo-unauthorized{
    border-right: 5px solid var(--main-color);
}

/* Spinner Loading Colour */
.spinner-loading{
    border-color: var(--main-color) !important;
}

/* Sidebar Logout Colour */
.btn-logout-container{
    border-top:2px solid var(--main-color);
}

/* Pengiriman Detail Form */
.col-table{
    border: 1px solid var(--main-color);
}

/* Button Secondary Colour */
.btn-secondary{
    background-color: white !important;
    color: var(--main-color) !important;
}
.btn-secondary:hover {
    border-color: var(--main-color) !important;
}

@media (max-width: 575px) {
    #logo-unauthorized {
        border-bottom: 5px solid var(--main-color);
    }
}