/* Table */
.container-table{
    margin-top: 3.5vh;
}
.form-filter{
    padding: 0 5vw 0 5vw;
    margin-bottom: 20px;
}
.form-filter > .title{
    text-align: center;
    font-size:24px;
    font-weight: bold;
}
.form-filter .form-label{
    font-size: 12px;
}
.form-filter button{
    width:100%;
}
.form-btn-filter{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.dataTables_wrapper,
.table_footer{
    max-width: 1080px;   
    font-size: 1em;
    margin-left: auto;
    margin-right: auto;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 2px 8px;
}
#data_table_wrapper{
    /* height: 500px; */
    text-align: left;
}
#data_table>thead>tr{
    height: 0px !important;
    border: none !important;
}
table.dataTable thead th, table.dataTable thead td{
    border: none;
}
.dataTables_length{
    padding : 14.25px;
    font-weight: bold;
}

/* Table Row */
table.dataTable tbody th, table.dataTable tbody td {
    padding: 5px 15px;
}
.center.pengiriman{
    font-size: 10px;
    height:100%;
    align-items: center;
}

/* Search Button */
.dataTables_filter>label>input{
    border-radius: 15px;
    border: none;
    outline: none;
    width: 220px;
    height: 30px;
    font-size: 1em;
    padding-left: 10px;
}
.dataTables_filter{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    font-weight: bold;
}

/* Scroll Bar */
::-webkit-scrollbar{
    width: 10px;
}

/* Icon */
.icon_container{
    display:flex;
    flex-direction:row;
    justify-content: space-around;
}
.icon{
    width:24px;
    padding:5px;
}
.icon:hover{
    cursor:pointer;
    filter: invert(25%) sepia(96%) saturate(6726%) hue-rotate(351deg) brightness(82%) contrast(79%);
}

/* Button Table */
.btn-table-container{
    text-align: left;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}
#btn-container{
    margin-top:5px;
    text-align: center;
    width: 135px;
}
#btn-tambah{
    width: 20%;
    font-size: 1em;
    margin-top: 15px;
}


/* Table Footer */
td.pengiriman{
    display: flex;
    justify-content: space-around;
}
.container-pagination{
    display: flex;
    justify-content: center;
    height: 80px;
}
.table_footer{
    width: 100%;
    padding: 10px;
    max-height: 75px;
}

/* Modal */
.modal-body{
    text-align: center;
    word-break: break-word;
}
.modal-footer{
    justify-content: space-evenly !important;
}
ul{
    text-align: left;
}

/* Modal Loading */
.modal-loading-content>div>.modal-content{
    background: transparent;
    outline: none;
    border: none;
    height: 25vw;
}
.modal-loading-body{
    background: transparent; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-loading{
    width: 5vw !important;
    height: 5vw !important;
    border-right-color: transparent !important;
}

/* Nama Supir Pada Tabel Premi */
#nama-supir{
    display: flex;
    justify-content: center;
}

#inv-cust-filter{
    font-size: 10px;
    color:red;
    margin-top:5px;
}

/* #rekap-filter-container{
    min-height: 105px !important;
} */

@media (max-width: 575px) {
    .container-table #nama-supir h4 {
        font-size: 0.65em;
        padding: 3px 8px;
    }
    .container-table {
        margin-bottom: 4vh;
    }
    .dataTables_length {
        padding-bottom: 8px;
        font-size: 0.6em;
    }
    table.dataTable thead th, table.dataTable thead td,
    table.dataTable tbody th, table.dataTable tbody td {
        font-size: 0.6em;
        padding: 5px;
    }
    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate {
        padding-top: 0;
        font-size: 0.6em;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 0;
    }
    .dataTables_wrapper .dataTables_filter {
        margin-top: 10px;
    }
    .dataTables_wrapper .dataTables_filter label {
        margin-bottom: 5px;
        font-size: 0.6em;
    }
    .dataTables_filter > label > input {
        height: 15px;
        width: 100%;
        margin-top: 5px;
    }
    .btn-table-container {
        margin: 0;
    }
    #btn-container {
        margin-top: 3px;
        width: 100%;
    }
    #btn-tambah {
        font-size: 0.6em;
        padding: 2px 0;
        margin-top: 20px;
    }
    .table_footer {
        height: 85px;
        max-height: none;
    }
    .form-filter {
        padding: 0;
        margin-bottom: 15px;
    }
    .form-filter .form-label {
        font-size: 7px;
    }
    input[type="date"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control,
    input[type="time"].form-control {
        font-size: 0.5em;
        padding: 0 5px;
    }
    .form-group select.form-control {
        font-size: 0.5em;
        padding: 0 5px;
    }
    .form-filter button {
        font-size: 0.6em;
        margin-bottom: 5px;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .container-table #nama-supir h4 {
        font-size: 0.65em;
        padding: 3px 8px;
    }
    .container-table {
        margin-bottom: 7vh;
    }
    .dataTables_length {
        padding-bottom: 8px;
        font-size: 0.7em;
    }
    table.dataTable thead th, table.dataTable thead td,
    table.dataTable tbody th, table.dataTable tbody td {
        font-size: 0.7em;
        padding: 8px;
    }
    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate {
        padding-top: 0;
        font-size: 0.7em;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 0;
    }
    .dataTables_wrapper .dataTables_filter {
        margin-top: 10px;
    }
    .dataTables_wrapper .dataTables_filter label {
        margin-bottom: 5px;
        font-size: 0.7em;
    }
    .dataTables_filter > label > input {
        height: 20px;
    }
    #btn-container {
        margin-top: 5px;
    }
    #btn-tambah {
        font-size: 0.7em;
        padding: 2px 0;
        margin-top: 25px;
    }
    .table_footer {
        height: 90px;
        max-height: none;
    }
    .form-filter {
        padding: 0;
        margin-bottom: 15px;
    }
    .form-filter .form-label {
        font-size: 10px;
    }
    input[type="date"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control,
    input[type="time"].form-control {
        font-size: 0.7em;
        padding: 0 5px;
    }
    .form-group select.form-control {
        font-size: 0.7em;
        padding: 0 5px;
    }
    .form-filter button {
        font-size: 0.7em;
        margin-bottom: 10px;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    .container-table #nama-supir h4 {
        font-size: 0.75em;
        padding: 5px 10px;
    }
    .dataTables_length {
        padding: 12px 12px 5px 12px;
        font-size: 0.75em;
    }
    table.dataTable thead th, table.dataTable thead td,
    table.dataTable tbody th, table.dataTable tbody td {
        font-size: 0.8em;
        padding: 10px;
    }
    .dataTables_wrapper .dataTables_info {
        font-size: 0.75em;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        font-size: 0.75em;
        padding: 0 3px;
    }
    .dataTables_wrapper .dataTables_filter {
        font-size: 0.8em;
    }
    .dataTables_filter > label > input {
        height: 25px;
    }
    #btn-tambah {
        font-size: 0.75em;
        margin-top: 0;
    }
    .form-filter {
        padding: 0;
    }
    input[type="date"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control,
    input[type="time"].form-control {
        font-size: 0.75em;
        padding: 0 5px;
    }
    .form-group select.form-control {
        font-size: 0.75em;
        padding: 0 5px;
    }
    .form-filter button {
        font-size: 0.8em;
        margin-bottom: 10px;
    }
    .table_footer {
        max-height: 60px;
    }
}

@media (min-width: 991px) and (max-width: 1440px) {
    .main {
        margin-left: 340px;
        margin-right: 35px;
    }
}